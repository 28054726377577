/*
        SUMMARY
            0 - Const / Variables
            1 - Function
*/


/*---------------------/ 1 - Const - Variables /-------------------*/


/*---------------------/ 2 - Functions /-------------------*/

var swiper = new Swiper(".mySwiper", {
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ["-20%", 0, -1],
      },
      next: {
        translate: ["100%", 0, 0],
      },
    },
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  var swiper = new Swiper(".mySwiper2", {
    loop: true,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var swiperProduto = new Swiper(".mySwiper3", {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1100: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1600: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
      }
  });

  var swiper4 = new Swiper(".mySwiper4", {
    slidesPerView: 3,
    slidesPerColumn: 3,
    slidesPerGroup :3,
    spaceBetween: 30,
    grid: {
        rows: 2,
        fill: "row",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


  var swiper5 = new Swiper(".mySwiper5", {
    slidesPerView: 3,
    slidesPerColumn: 3,
    slidesPerGroup :3,
    spaceBetween: 30,
    grid: {
        rows: 2,
        fill: "row",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


    // Button click event listener
    document.querySelector('.btn-next-5').addEventListener('click', function () {
    swiper5.slideNext();
    });
    document.querySelector('.btn-prev-5').addEventListener('click', function () {
    swiper5.slidePrev();
    })
    // Button click event listener
    document.querySelector('.btn-next-4').addEventListener('click', function () {
        swiper4.slideNext();
    });
    document.querySelector('.btn-prev-4').addEventListener('click', function () {
        swiper4.slidePrev();
    })



   // Button click event listener
   document.getElementById('btn-nextOut').addEventListener('click', function () {
    swiperProduto.slideNext();
  });
  document.getElementById('btn-prevOut').addEventListener('click', function () {
    swiperProduto.slidePrev();
  })

  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
});

// atuation animation cards

const cards = document.querySelectorAll('.atuation--card');

cards.forEach((card) => {
    const title = card.querySelector('.title');
    const text = card.querySelector('.text');
    let tl = gsap.timeline({
        paused: true
    });
    tl.to(text, {
        height:'100%',
        ease: 'expo.inOut',
        duration: .3,
    })
    tl.to(text, {
        opacity: 1,
        ease: 'expo.inOut',
        duration: .3,
    }, '-=.2')
  card.addEventListener('mouseenter', () => {
    tl.play();
    gsap.to(card, {
        "--opacity": 1
    })
    gsap.to(title, {
        opacity: 0,
    });
  });

  card.addEventListener('mouseleave', () => {
    tl.reverse();
    gsap.to(card, {
        "--opacity": 0
    })
    gsap.to(title, {
        opacity: 1,
    });
  });
});




